
























































import Product from "@/models/Product"
import ProductFeatureType from "@/models/ProductFeatureType"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import CartModule from "@/store/CartModule"
import { getModule } from "vuex-module-decorators"
import CartTool from "@/services/tool/CartTool"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import ProductService from "@/services/ProductService";
import SessionModule from "@/store/SessionModule";
import FavouritesModule from "@/store/FavouritesModule";

@Component
export default class ProductComponent extends Vue {

  @Prop() readonly product!: Product
  @Prop() readonly featuredMode!: boolean
	favorites: any = getModule(FavouritesModule).favoriteProducts
	BASE_URL = ConstantTool.BASE_URL
  image: string | undefined = ""


  get isLoged(): boolean { return LoginService.isLoged() }

	get isFavorite() { return this.favorites.some((f: Product) => f.id == this.product.id) }

  cartModule: CartModule = getModule(CartModule)
	sessionModule: SessionModule = getModule(SessionModule)

  openProductDialog() {
    this.$emit('openProductDialog', this.product)
  }

  created() {
      this.product.quantity = 1
    this.refresh()
  }

  errorHandler() {
    this.image = "../assets/ban.svg"
    this.$forceUpdate()
  }

  refresh() {
    this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
  }

	addFavoriteProduct(product: Product) {
		ProductService.relateFavoriteProduct(this, this.sessionModule.session.user.id!, product.id!)
	}

  @Watch('product')
  onProductChanged() {
    this.refresh()
  }
}
