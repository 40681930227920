













import Product from "@/models/Product"
import ProductFeatureType from "@/models/ProductFeatureType"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import CartModule from "@/store/CartModule"
import { getModule } from "vuex-module-decorators"
import CartTool from "@/services/tool/CartTool"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import ProductService from "@/services/ProductService";
import SessionModule from "@/store/SessionModule";

@Component
export default class VideoProductComponent extends Vue {

	@Prop() readonly product!: Product
	BASE_URL = ConstantTool.BASE_URL
	image: string = ""

	get isLoged(): boolean {
		return LoginService.isLoged()
	}

	cartModule: CartModule = getModule(CartModule)
	sessionModule: SessionModule = getModule(SessionModule)

	openProductDialog() {
		this.$emit('openProductDialog', this.product)
	}

	created() {
		this.refresh()
	}

	errorHandler() {
		this.image = "../assets/ban.svg"
		this.$forceUpdate()
	}

	refresh() {
		this.image = `${this.BASE_URL}/storage/ftp/art_${this.product.connectorId?.toLowerCase()}_1_tmb.jpg`
	}

	addFavourite() {
		if (this.isLoged) {
			getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`)
		} else {
			this.$router.push("/login")
		}
	}

	addFavoriteProduct(product: Product) {
		ProductService.relateFavoriteProduct(this, this.sessionModule.session.user.id!, product.id!)
	}

	@Watch('product')
	onProductChanged() {
		this.refresh()
	}
}
