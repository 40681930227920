import {Vue} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import JsonTool from "@/services/tool/JsonTool"
import Catalogue from "@/models/Catalogue";
import axios from "axios";
import Response from "@/models/responses/Response";

export default class CatalogueService {

    static async getActiveCatalogue2(): Promise<Response<Catalogue>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/public/catalogues/active")
            const catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
            return Promise.resolve({ result: catalogue })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async getActiveCatalogue(component: Vue) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/catalogues/active")
            // @ts-ignore
            component.catalogue = JsonTool.jsonConvert.deserializeObject(response.data, Catalogue)
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se ha podido obtener el catalogo.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }
}