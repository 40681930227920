import DateTimeConverter from "@/services/converters/DateTimeConverter"
import {JsonObject, JsonProperty} from "json2typescript"
import Auditing from "@/models/Auditing"
import {DateTime} from "luxon"
import Document from "@/models/Document";

@JsonObject("Catalogue")
export default class Catalogue extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined
    @JsonProperty("document", Document, true)
    document?: Document = undefined
    @JsonProperty("active", Boolean, true)
    active?: boolean = undefined
    @JsonProperty("activatedAt", DateTimeConverter, true)
    activatedAt?: DateTime = undefined

}