
















































































































































































import {Component, Vue} from 'vue-property-decorator'
import CarouselComponent from "@/components/CarouselComponent.vue"
import NewsletterComponent from "@/components/NewsletterComponent.vue"
import ProductComponent from "@/components/ProductComponent.vue"
import ProductCategory from '@/models/ProductCategory'
import Product from '@/models/Product'
import ProductService from '@/services/ProductService'
import LangModule from '@/store/LangModule'
import {getModule} from 'vuex-module-decorators'
import ProductDialog from '@/components/dialogs/ProductDialog.vue'
import ProductCategoryService from "@/services/ProductCategoryService"
import PerfectBeautyLogo from "@/assets/custom_icons/perfect_beauty.svg"
import FacebookIcon from "@/assets/custom_icons/facebook.svg"
import LinkedinIcon from "@/assets/custom_icons/linkedin.svg"
import InstagramIcon from "@/assets/custom_icons/instagram.svg"
import YoutubeIcon from "@/assets/custom_icons/youtube.svg"
import ContactComponent from "@/components/ContactComponent.vue";
import QueryComponent from "@/components/QueryComponent.vue";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import VideoProductComponent from "@/components/products/VideoProductComponent.vue";
import Banner, {BannerReference} from "@/models/Banner";
import CatalogueService from "@/services/CatalogueService";
import Catalogue from "@/models/Catalogue";
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";


@Component({components: {CarouselComponent, NewsletterComponent, ProductComponent, ProductDialog, PerfectBeautyLogo, FacebookIcon, LinkedinIcon, InstagramIcon, YoutubeIcon, ContactComponent, QueryComponent, VideoProductComponent }})
export default class HomeView extends Vue {

	lang: any = getModule(LangModule).lang
	categoryTreeviewModule = getModule(CategoryTreeviewModule)
	model: any = 0
	product: Product = new Product()
	productDialog: boolean = false
	featuredProducts: Product[] = []
	featuredCategories: ProductCategory[] = []
	featuredVideos: Product[] = []
  catalogue2: SingleItem<Nullable<Catalogue>> = { item: null  }
  loading: boolean = false

	get productCategories() { return this.categoryTreeviewModule.productCategories }
	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }

	get bannerReference() { return BannerReference }
	banners: Banner[] = []

	openProductDialog(product: Product) {
		this.productDialog = true
		this.product = product
	}

	redirectToProduct(product: Product) {
		this.$router.push(`/video/${product.id}`)
	}

	created() {
    this.getCatalogue()
		ProductService.getFeaturedProducts(this, this.featuredProducts, 0, 10, [], null, true)
		ProductCategoryService.getProductCategories(this, this.featuredCategories, true)
		ProductService.getFeaturedProducts(this, this.featuredVideos, 0, 10, [], true, true)
	}

	treeToList(tree: ProductCategory) {
		const result = [];
		result.push(tree);
		const children = tree.children || [];
		children.forEach(child => {
			const childResult = this.treeToList(child);
			result.push(...childResult)
		})
		return result;
	}

	onCategoryClicked(productCategory: ProductCategory) {
		console.log(productCategory)
		let ids: number[] = []
		this.treeToList(productCategory).forEach(cat => ids.push(cat.id!))
		this.selectedCategories = ids
		this.$router.push('/products')
	}

  downloadCat() {
    if (this.catalogue2.item!!.id) { window.open(this.catalogue2.item!!.document!.url!, '_blank') }
  }

  async getCatalogue() {
    try {
      await Handler.getItem(this, this.catalogue2, () =>
          CatalogueService.getActiveCatalogue2()
      )
    } catch (e) { console.log(e) }
    // CatalogueService.getActiveCatalogue(this)
  }

}
